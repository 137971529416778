import React from 'react';
import { graphql } from 'gatsby';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import Layout from '../components/layout';
import { Container } from '../components/styled';

const MyMapComponent = withGoogleMap(props => (
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 52.490869, lng: 13.322885 }}
  >
    {props.isMarkerShown && (
      <Marker position={{ lat: 52.490869, lng: 13.322885 }} />
    )}
  </GoogleMap>
));

const KontaktPage = ({ data }) => (
  <Layout>
    <MyMapComponent
      isMarkerShown
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `400px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
    <Container dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
  </Layout>
);

export default KontaktPage;

export const pageQuery = graphql`
  query kontaktQuery($id: String) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`;
